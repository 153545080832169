.adyen-checkout-disclaimer__label {
  position: relative;
  margin-top: 16px;
  padding-left: 0;
  cursor: pointer;
  display: inline-block;
  line-height: 19px;
  color: #00112c;
  font-size: 0.81em;
  font-weight: normal;
  user-select: none;
}
[dir=rtl] .adyen-checkout-disclaimer__label {
  padding-right: 0;
}